import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { Icon, PhotoIcon, VideoIcon } from '../Icon'
import styles from './ContentMarker.style'

const ContentMarkerStyled = styled.button(props => ({ ...styles(props) }))

function ContentMarker({ className, size, variant, ...rest }) {
    return (
        <ContentMarkerStyled
            className={className}
            size={size}
            variant={variant}
            aria-label={variant === 'photo' ? 'Galerija' : 'Video'}
            {...rest}>
            <Icon icon={variant === 'photo' ? PhotoIcon : VideoIcon} viewBox="0 0 60 60" />
        </ContentMarkerStyled>
    )
}

const contentMarkerVariants = Object.freeze({
    PHOTO: 'photo',
    VIDEO: 'video'
})

const contentMarkerSizes = Object.freeze({
    SMALL: 'small',
    NORMAL: 'normal',
    LARGE: 'large'
})

ContentMarker.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf([...Object.values(contentMarkerSizes)]),
    variant: PropTypes.oneOf([...Object.values(contentMarkerVariants)])
}

ContentMarker.defaultProps = {
    className: undefined,
    size: contentMarkerSizes.NORMAL,
    variant: contentMarkerVariants.PHOTO
}

export { contentMarkerVariants, contentMarkerSizes }

export default withErrorBoundary(ContentMarker, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[VideoContentMarker]: ', error, componentStack)
    }
})
