export function objectToArrayOrAny(possibleObject) {
    if (Array.isArray(possibleObject)) {
        return possibleObject
    }

    if (typeof possibleObject === 'object' && possibleObject !== null) {
        return Object.values(possibleObject)
    }

    return possibleObject
}
