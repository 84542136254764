import { toRem } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys */
const style = ({ theme, size }) => {
    const config = {
        small: {
            xs: {
                height: toRem(32),
                width: toRem(32),
                fontSize: toRem(32)
            },
            md: {
                height: toRem(32),
                width: toRem(32),
                fontSize: toRem(32)
            }
        },
        normal: {
            xs: {
                height: toRem(60),
                width: toRem(60),
                fontSize: toRem(60)
            },
            md: {
                height: toRem(60),
                width: toRem(60),
                fontSize: toRem(60)
            }
        },
        large: {
            xs: {
                height: toRem(70),
                width: toRem(70),
                fontSize: toRem(70)
            },
            md: {
                height: toRem(100),
                width: toRem(100),
                fontSize: toRem(100)
            }
        }
    }

    return {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: config[size]?.xs.width,
        height: config[size]?.xs.height,
        fontSize: config[size]?.xs.fontSize,
        backgroundColor: theme.colors.media?.background,
        color: theme.colors.media?.color,
        [theme.breakpoints.up('md')]: {
            width: config[size]?.md.width,
            height: config[size]?.md.height,
            fontSize: config[size]?.md.fontSize
        },
        svg: {
            path: {
                fill: 'currentColor'
            }
        }
    }
}

export default style
