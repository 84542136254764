/* eslint-disable quote-props, no-dupe-keys, max-len */
import { toRem, toRems } from '../../../../helpers/theme'

const style = ({ theme, inverted, embedded, variant, decorationVariant, contentMarkerVariant, contentMarkerSize }) => {
    const config = {
        content: {
            background: inverted
                ? theme.colors.card.inner.content.background.inverted
                : theme.colors.card.inner.content.background.default
        },
        xs: {
            wrapper: {
                variant: {
                    alpha: {
                        padding: 0
                    },
                    beta: {
                        padding: 0
                    },
                    gamma: {
                        padding: 0
                    },
                    delta: {
                        padding: 0
                    },
                    epsilon: {
                        padding: 0
                    },
                    zeta: {
                        padding: toRems([0, 15])
                    },
                    eta: {
                        padding: 0
                    },
                    theta: {
                        padding: 0
                    }
                }
            },
            content: {
                variant: {
                    alpha: {
                        width: `calc(100% - ${toRem(45)})`,
                        padding: toRems([15, 15, 15, 25]),
                        margin: toRems([-50, 0, 0, 0])
                    },
                    beta: {
                        width: '70%',
                        padding: inverted ? toRems([20, 15, 18, 25]) : toRems([20, 15, 18, 25]),
                        margin: 0
                    },
                    gamma: {
                        width: '100%',
                        padding: inverted ? toRems([18, 15, 18, 20]) : toRems([0, 15, 0, 20]),
                        margin: 0
                    },
                    delta: {
                        width: `calc(100% - ${toRem(50)})`,
                        padding: toRems([18, 15, 18, 25]),
                        margin: 0
                    },
                    epsilon: {
                        width: '100%',
                        padding: toRem(10),
                        margin: 0
                    },
                    zeta: {
                        width: '100%',
                        padding: 0,
                        margin: 0
                    },
                    eta: {
                        width: `calc(100% - ${toRem(50)})`,
                        padding: toRems([18, 15, 18, 25]),
                        margin: 0
                    },
                    theta: {
                        width: '100%',
                        padding: inverted ? toRems([18, 15, 18, 20]) : toRems([0, 15, 0, 20]),
                        margin: 0
                    }
                },
                font: {
                    alpha: {
                        label: {
                            fontSize: toRem(16)
                        },
                        title: {
                            fontSize: toRem(24)
                        }
                    },
                    beta: {
                        label: {
                            fontSize: toRem(16)
                        },
                        title: {
                            fontSize: toRem(24)
                        }
                    },
                    gamma: {
                        label: {
                            fontSize: toRem(15)
                        },
                        title: {
                            fontSize: toRem(20)
                        }
                    },
                    delta: {
                        label: {
                            fontSize: toRem(16)
                        },
                        title: {
                            fontSize: toRem(24)
                        }
                    },
                    epsilon: {
                        label: {
                            fontSize: toRem(15)
                        },
                        title: {
                            fontSize: toRem(20)
                        }
                    },
                    zeta: {
                        label: {
                            fontSize: toRem(15)
                        },
                        title: {
                            fontSize: toRem(20)
                        }
                    },
                    eta: {
                        label: {
                            fontSize: toRem(16)
                        },
                        title: {
                            fontSize: toRem(24)
                        }
                    },
                    theta: {
                        label: {
                            fontSize: toRem(13),
                            '@media (min-width:370px)': {
                                fontSize: toRem(15)
                            }
                        },
                        title: {
                            fontSize: toRem(17),
                            '@media (min-width:370px)': {
                                fontSize: toRem(18)
                            }
                        }
                    }
                }
            },
            decoration: {
                variant: {
                    alpha: {
                        top: toRem(50),
                        left: 0,
                        right: 'initial'
                    },
                    beta: {
                        top: toRem(-20),
                        left: 'initial',
                        right: 0
                    },
                    gamma: {
                        top: toRem(-32),
                        left: 'initial',
                        right: 0
                    }
                }
            }
        },
        md: {
            wrapper: {
                variant: {
                    alpha: {
                        padding: 0
                    },
                    beta: {
                        padding: 0
                    },
                    gamma: {
                        padding: toRems([0, 50, 0, 50])
                    },
                    delta: {
                        padding: toRems([0, 0, 0, 50])
                    }
                }
            },
            content: {
                variant: {
                    alpha: {
                        width: `calc(100% - ${toRem(50)})`,
                        padding: toRems([14, 15, 14, 25]),
                        margin: toRems([-50, 0, 0, 0])
                    },
                    beta: {
                        width: '70%',
                        padding: inverted ? toRems([20, 15, 18, 25]) : toRems([20, 15, 18, 25]),
                        margin: 0
                    },
                    gamma: {
                        width: '100%',
                        padding: toRems([18, 15, 18, 18]),
                        margin: toRems([-50, 0, 0, -100])
                    },
                    delta: {
                        width: `calc(100% - ${toRem(50)})`,
                        padding: toRems([18, 15, 18, 25]),
                        margin: toRems([-50, 0, 0, -50])
                    },
                    epsilon: {
                        width: '100%',
                        padding: toRems([15, 0]),
                        margin: 0
                    },
                    eta: {
                        width: `calc(100% - ${toRem(100)})`,
                        padding: toRems([18, 15, 18, 25]),
                        margin: 0
                    },
                    theta: {
                        width: '100%',
                        padding: inverted ? toRems([18, 15, 18, 20]) : toRems([0, 15, 0, 20]),
                        margin: 0
                    }
                },
                font: {
                    alpha: {
                        label: {
                            fontSize: toRem(15)
                        },
                        title: {
                            fontSize: toRem(24)
                        }
                    },
                    beta: {
                        label: {
                            fontSize: toRem(15)
                        },
                        title: {
                            fontSize: toRem(24)
                        }
                    },
                    gamma: {
                        label: {
                            fontSize: toRem(15)
                        },
                        title: {
                            fontSize: toRem(26)
                        }
                    },
                    delta: {
                        label: {
                            fontSize: toRem(15)
                        },
                        title: {
                            fontSize: toRem(26)
                        }
                    },
                    epsilon: {
                        label: {
                            fontSize: toRem(15)
                        },
                        title: {
                            fontSize: toRem(26)
                        }
                    },
                    zeta: {
                        label: {
                            fontSize: toRem(15)
                        },
                        title: {
                            fontSize: toRem(26)
                        }
                    },
                    theta: {
                        label: {
                            fontSize: toRem(18)
                        },
                        title: {
                            fontSize: toRem(24)
                        }
                    }
                }
            },
            decoration: {
                variant: {
                    alpha: {
                        top: toRem(90),
                        left: toRem(-100),
                        right: 'initial'
                    },
                    beta: {
                        top: toRem(-20),
                        left: 'initial',
                        right: toRem(-25)
                    },
                    gamma: {
                        top: toRem(-45),
                        left: toRem(-50),
                        right: 'initial'
                    }
                }
            }
        }
    }

    return {
        position: 'relative',
        padding: config?.xs.wrapper.variant[variant]?.padding,
        [theme.breakpoints.up('md')]: {
            padding: config?.md.wrapper.variant[variant]?.padding
        },
        ...(embedded && {
            marginBottom: toRem(30),
            '& .cardEmbedded_graphic': {
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%'
            }
        }),
        '& .cardInner': {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            color: theme.colors.text.default,
            border: 'none',
            ...(['beta', 'gamma'].includes(variant) && {
                flexDirection: 'row',
                alignItems: 'center',
                [theme.breakpoints.up('md')]: {
                    flexDirection: 'column'
                }
            }),
            ...(variant === 'theta' && {
                maxHeight: toRem(110),
                flexDirection: 'row',
                alignItems: 'center'
            }),
            ...(variant === 'beta' && {
                paddingRight: '30%'
            }),
            ...(embedded && {
                padding: toRems([20, 0, 20, 45])
            }),
            '&:hover': {
                border: 'none'
            }
        },
        '& .cardImage': {
            position: 'relative',
            width: '100%',
            ...(variant === 'gamma' && {
                [theme.breakpoints.down('sm')]: {
                    maxWidth: toRem(80)
                }
            }),
            ...(variant === 'theta' && {
                maxWidth: toRem(80)
            }),
            img: {
                margin: 0
            }
        },
        '& .cardImage_frame': {
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%'
        },
        '& .cardImage_triangle': {
            position: 'absolute',
            top: 0,
            right: 0
        },
        '& .cardImage_graphic': {
            position: 'absolute',
            top: config?.xs.decoration.variant[decorationVariant]?.top,
            left: config?.xs.decoration.variant[decorationVariant]?.left,
            right: config?.xs.decoration.variant[decorationVariant]?.right,
            [theme.breakpoints.up('md')]: {
                top: config?.md.decoration.variant[decorationVariant]?.top,
                left: config?.md.decoration.variant[decorationVariant]?.left,
                right: config?.md.decoration.variant[decorationVariant]?.right
            }
        },
        '& .contentMarker_wrapper': {
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
            width: `calc(50% + ${toRem(30)})`
        },
        '& .contentMarker_graphic': {
            position: 'absolute',
            ...(contentMarkerVariant === 'video' && {
                left: toRem(-72)
            }),
            ...(contentMarkerVariant === 'photo' && {
                width: `calc(100% - ${toRem(55)})`,
                right: 0
            }),
            ...(contentMarkerSize === 'small' && {
                width: `calc(100% - ${toRem(15)})`
            })
        },
        '& .cardContent': {
            background: config.content.background,
            width: config?.xs.content.variant[variant]?.width,
            padding: config?.xs.content.variant[variant]?.padding,
            margin: config?.xs.content.variant[variant]?.margin,
            // marginLeft: rightAlign ? 'auto' : null,
            zIndex: theme.zIndex.over,
            ...(['beta', 'eta'].includes(variant) && {
                position: 'absolute',
                bottom: 'initial',
                top: '70%',
                right: 0
            }),
            ...(variant === 'delta' && {
                position: 'absolute',
                bottom: 0
            }),
            [theme.breakpoints.up('md')]: {
                width: config?.md.content.variant[variant]?.width,
                padding: config?.md.content.variant[variant]?.padding,
                margin: config?.md.content.variant[variant]?.margin,
                ...(['beta', 'eta'].includes(variant) && {
                    top: '80%'
                })
            }
        },
        '& .cardContent_label': {
            marginBottom: toRem(4),
            color: inverted
                ? theme.colors.card.inner.content.text.inverted
                : theme.colors.card.inner.content.text.default,
            fontSize: config?.xs.content.font[variant]?.label.fontSize,
            lineHeight: 1.4,
            [theme.breakpoints.up('md')]: {
                marginBottom: 0,
                fontSize: config?.md.content.font[variant]?.label.fontSize,
                lineHeight: 1.6
            }
        },
        '& .cardContent_title': {
            margin: 0,
            color: inverted
                ? theme.colors.card.inner.content.title.inverted
                : theme.colors.card.inner.content.title.default,
            fontFamily: theme.typography.fontFamilyAlt,
            fontSize: config?.xs.content.font[variant]?.title.fontSize,
            fontWeight: theme.typography.fontWeight.regular,
            lineHeight: 1.4,
            transitionDuration: theme.transitions.default.duration,
            transitionTimingFunction: theme.transitions.default.timingFunction,
            transitionDelay: theme.transitions.default.delay,
            transitionProperty: 'color',
            [theme.breakpoints.up('md')]: {
                fontSize: config?.md.content.font[variant]?.title.fontSize
            }
        },
        '& .cardContent_columnist': {
            fontSize: toRem(14),
            color: theme.colors.card.inner.content.text.accent
        },
        '&:hover': {
            '& .cardContent_title': {
                color: theme.colors.card.inner.content.title.hover
            }
        }
    }
}

export default style
